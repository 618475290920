

.portfolio-container{
    
    
    display: flex;
    flex-direction: column;
    padding: calc(var(--vertical-padding) + 10rem) 0;
    
    h4{
        padding: 0 calc(var(--horizontal-padding) + 2.5rem);
        font-weight: bold;
        color: var(--dark-color);
    }
    h6{
        padding: 0 calc(var(--horizontal-padding) + 2.5rem);
        color: var(--dark-color);
        font-weight: 400;
        padding-bottom: 2rem;
    }
}

.portfolio-grid{
    
    display: flex;
    flex-direction: column;
    align-items: center;
   
    padding: 2rem 6.5rem;
    h5{
        padding: 0.1rem 1rem;
    }
    .project-description{
        padding: 0.1rem 1rem 1rem 1rem;
    }
}

.project-details{
    padding: 2.5rem; 
    width: 86%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.project { 
    height: 60vh;
    width: 650px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    div{
        z-index: 10;
    }
    h5{
        z-index: 10;
        color: var(--light-color);
    }
    p{
        z-index: 10;
        color: var(--light-color);
    }
    
    
}






.project-image{
    position: absolute;
    border-radius: 20px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow-clip-margin: content-box;
    overflow: clip;
    
}

.saambhavi{
    background: radial-gradient(circle, rgba(174,58,86,1) 0%, rgba(93,24,49,1) 100%);
}

.project-type-container{

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 2rem;
    background-color: rgba($color: #7b7b7b, $alpha: 0.4);
    backdrop-filter: blur(4px) saturate(180%);
    -webkit-backdrop-filter: blur(4px) saturate(180%);
    border-radius: 25px;
    
}



.horizontal-scroll-section{
    position: relative;
    height: 250vh;
    width: max-content;
}



.horizontal-scroll-div{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: auto;
    padding-top: 100px;
    overflow: hidden;
}

.horizontal-scroller{
    padding-left: calc(var(--horizontal-padding));
    display: flex;
    gap: 2.25rem;
}

@media screen and (max-width: 550px){

    .portfolio-container{
        display: flex !important;
        flex-direction: column !important;
        padding: 2rem 2rem !important; 
        
        h4{
            padding: 0 !important;
            text-align: left !important;
            font-weight: bold !important;
            font-size: 48px !important;
        }
        h6{
            padding: 0 !important;
            text-align: left !important;
            font-weight: 400 !important;
            font-size: 18px !important;
        
        }

        .horizontal-scroll-section{
            height: auto !important;
            width: auto !important;
        }

        .horizontal-scroll-div{
            height: auto !important;
            padding-top: 20px !important;
        }

        .horizontal-scroller{
            padding: 1rem 0rem !important;
            display: block !important;
            transform: translateX(0%) !important;
            
        }

        .project{
            border-radius: 25px !important;
            width: 100% !important;
            height: 250px !important;
            margin: 1rem 0 !important;
            
        }

        .project-image{
            width: 400px !important;
        }

        .project-description{
            display: none !important;
        }

        .project-details{
            height: 100% !important;
            flex-direction: column-reverse !important;
            align-items: flex-start !important;
            padding: 1.5rem 2.5rem !important;
           
        }

        .project-type-container{
            padding: 0.5rem 1rem !important;
        }

        
    }

}

@media screen and (max-width: 1024px){

    .sticky-section{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .portfolio-container{
        display: flex;
        flex-direction: column;
        padding: 2rem 4rem;
        
        h4{
            
            text-align: center;
            
        }
        h6{    
            text-align: center;
        }

        .horizontal-scroll-section{
            height: auto;
        }

        .horizontal-scroll-div{
            height: auto;
            padding-top: 20px;
        }

        .horizontal-scroller{
            padding: 1rem 0rem;
            display: block;
            transform: translateX(0%) !important;
            
        }

        .project{
            border-radius: 25px;
            width: 100%;
            height: 450px;
            margin: 1.5rem 0;
            
        }

        .project-image{
            width: 100%
        }
    }


}
