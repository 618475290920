.clients-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    h6{
        font-weight: 400;
    }
}

.clients-list{
    padding-top: 5rem;
    
    display: flex;
    align-items: center;
    .client-box{
        margin: 0 1rem;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        height: 175px;
        width: 320px;
        border: 0.2px solid var(--dark-color);
       

    }
    img{
        height: 50px;
        mix-blend-mode: multiply;
    }
}

@media screen and (max-width: 850px){
    
    .clients-container{
        padding: 2rem 4rem;
        height: auto;
        h4{
            text-align: center;
        }
    
        h6{
            text-align: center;
        }
    }

    

}


@media screen and (max-width: 720px){
    
    .clients-container{
        display: block;
        padding: 2rem 2rem;
        flex-direction: column;
    }



    .clients-list{
        padding: 2rem 0;
        flex-direction: column;
        .client-box{

            margin: 1rem 0;
            
            
        }
        h4{
            text-align: center !important;
            font-size: 48px;
        }
    
        h6{
            text-align: center !important;
            font-size: 18px;
        }

    
    }

    

}

