@font-face {
  font-family: "Manrope";   /*Can be any text*/
  src: local("Manrope"),
    url("./Font/Manrope.woff2") format("woff");
}

html{
  overflow-x: hidden
}

*{
  list-style: none;
  
  margin: 0;
  font-family: "Manrope";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --light-color: #FBFBFB;
  --dark-color: #24292e;
 
  --dark-color-low-opacity: #11171d7d;
  --light-color-low-opacity: #fbfbfb7d;
  --vertical-padding: 2rem;
  --horizontal-padding: 3.125rem;
  /* border: 0.5px solid red !important; */
 
}

ul{
  padding-inline-start: 0px
}


.website-container{
  scroll-snap-type: y mandatory;
}

.website-container::after{
  animation: grain 8s steps(10) infinite;
  background-image: url("http://assets.iceable.com/img/noise-transparent.png");
  content: "";
  height: 300%;
  left: -50%;
  opacity: 0.5;
  position: fixed;
  top: -100%;
  width: 300%;
  z-index: -1;



  /* content: "";
	background-image: url('http://assets.iceable.com/img/noise-transparent.png');
	opacity: 0.6;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;    */
}

@keyframes grain {
  0%, 100% { transform:translate(0, 0) }
  10% { transform:translate(-5%, -10%) }
  20% { transform:translate(-15%, 5%) }
  30% { transform:translate(7%, -25%) }
  40% { transform:translate(-5%, 25%) }
  50% { transform:translate(-15%, 10%) }
  60% { transform:translate(15%, 0%) }
  70% { transform:translate(0%, 15%) }
  80% { transform:translate(3%, 35%) }
  90% { transform:translate(-10%, 10%) }
}

section{
  scroll-snap-align: start;
}

.dark-bg-color{
  background-color: var(--dark-color);
  transition: 0.4s;
}

.light-bg-color{
  background-color: var(--light-color);
  transition: 0.4s;
}

.dark-color{
  color: var(--dark-color);
  transition: 0.4s;
}

.light-color{
  color: var(--light-color);
  transition: 0.4s;
}

.dark-color-opacity{
  color: var(--dark-color-low-opacity);
  transition: 0.4s;
}

.light-color-opacity{
  color: var(--light-color-low-opacity);
  transition: 0.4s;
}

html, body {
  min-height: 100%;
  height: auto;
}

html{
  scroll-behavior: initial !important;
}

p{
  font-size: 16px;
  color: var(--dark-color)

}

h1{
  font-size: 110px;
  color: var(--dark-color)

}

h2{
  font-size: 177px;
  color: var(--dark-color)
}

h3{
  font-size: 110px;
  color: var(--dark-color)
}

h4{

  font-size: 68px;
  color: var(--dark-color)


}

h5{

  font-size: 42px;
  color: var(--dark-color)

}

h6{
  font-size: 25px;
  color: var(--dark-color)

}

/* body:before {
  content: "";
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200vh;
  background: transparent url('http://assets.iceable.com/img/noise-transparent.png') repeat 0 0;
  background-repeat: repeat;
  opacity: 0.4;
  z-index: -1000;
  visibility: visible; 
  /* animation: bg-animation .2s infinite;
  
}*/
 
@-ms-viewport{
  width: device-width;
}


@keyframes bg-animation {
  0% { transform: translate(0,0) }
  10% { transform: translate(-5%,-5%) }
  20% { transform: translate(-10%,5%) }
  30% { transform: translate(5%,-10%) }
  40% { transform: translate(-5%,15%) }
  50% { transform: translate(-10%,5%) }
  60% { transform: translate(15%,0) }
  70% { transform: translate(0,10%) }
  80% { transform: translate(-15%,0) }
  90% { transform: translate(10%,5%) }
  100% { transform: translate(5%,0) }
}
