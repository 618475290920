.footer-container{
    height: auto;
    padding: var(--vertical-padding) var(--horizontal-padding);
   
}

.footer-center{
    display: flex;
    ul{
        list-style: none;
    }
    p{
        padding: 2px 0;
    }
    li{
        padding: 2px 0;
        cursor: pointer;
        transition: 0.2s ease-in-out;
    }
    li:hover{
        font-weight: 700;
        transition: 0.2s ease-in-out;
    }
    
}

.list-title{
    font-weight: 800;
}

.page-links{
    padding-right: 5rem;
}

.footer-start{
    display: flex;
    justify-content: space-between;
    h5{
        font-weight: 400;
        text-decoration: underline;
    }
    padding-bottom: 4rem;
}

.arrow-top {
    overflow-x: visible;
    box-sizing: border-box;
    position: relative;
    color: var(--dark-color);
    display: block;
    transform: scale(var(--ggs,1));
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    box-shadow: inset 0 0 0 2px;
    width: 110px;
    height: 5.5px;
    transition: 1s;
}



.arrow-top::after {
    overflow-x: visible;
    content: "";
    color: var(--dark-color);
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 7px;
    height: 7px;
    border-top: 1.5px solid;
    border-right: 1.5px solid;
    transform: rotate(45deg);
    right: 0;
    bottom: -2.85px
}

.scroll-button-top{
    position: absolute;
    right: -40px;
    width: 200px;
    height: 50px;
    rotate: -90deg;
    margin-top: 40px;
}

.scroll-button-top:hover{
    cursor: pointer;
    .arrow-top{
        width: 160px;
        transition: 1s;
        
    }
}

.footer-end{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    img{
        height: 14vw;
    }
}

@media screen and (max-width: 950px){

    .scroll-button-top{
        display: none;
    }

}

@media screen and (max-width: 550px){

    .footer-container{
        padding: 1rem 1rem;
        height: auto;

        li{
            font-size: 12px;
        }
    }

    .footer-end{
        p{
            font-size: 12px;
        }
    }
    .footer-center{
        padding-bottom: 2rem;
    }

    .xenith-footer{
        display: none;
    }

    .scroll-button-top{
        display: none;
    }

    .footer-start{
        padding-bottom: 2rem;
        h4{
            font-size: 40px;
            font-weight: 800;
        }
    }

}