.slogan-containter{
    display: flex;
    padding: 5rem calc(var(--horizontal-padding) + 1rem) 20rem calc(var(--horizontal-padding) + 1rem);
    align-items: center;
    height: auto;
    
    div{
        width: 100%;
        
    }

    h6{
        color: var(--light-color);
        padding-bottom: 1.3rem;
    }
    
    h4{
        display: flex;
        flex-wrap: wrap;    
        font-weight: 600;
        max-width: 70vw;
        .slogan-text{
            font-size: 67.77px;
            margin-right: 12px;
            color: var(--light-color);
            .slogan-shadow{
                position: absolute;
                opacity: 0.1;
            }

        }
    }
    
    

    
    span{ 
        font-weight: 600;
    }
    
    
}

@media screen and (max-width: 550px){

    
    .slogan-containter{
        padding: 6rem 2rem;
        height: auto;
        h6{
            font-size: 20px;
            font-weight: 600;
            padding-bottom: 2rem;
            }
            h4{
                display: flex;
                flex-wrap: wrap;    
                font-weight: 600;
                max-width: 100%;
                width: 100%;
                .slogan-text{
                    font-size: 48px;
                   
                    color: var(--light-color);
                    .slogan-shadow{
                        position: absolute;
                        opacity: 0.1;
                    }
        
                }
            }

    }

}

