nav{
    display: flex;
    justify-content: space-between;
    padding: var(--vertical-padding) var(--horizontal-padding);
    
}



.nav-container{
    display: flex;
    padding: var(--vertical-padding) var(--horizontal-padding) var(--horizontal-padding) var(--horizontal-padding);
    justify-content: space-between;
    align-items: center;
}

.nav-items{
    display: flex;
    list-style: none;

    li{
        cursor: pointer;
        transition: 0.2s ease-in-out;

    }

    li:hover{
        font-weight: 700;
        transition: 0.2s ease-in-out;

    }

}

.contact-button{
    
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 40px;
    border: 1px solid var(--dark-color);
    border-radius: 50px;
    padding: 0.75rem 2rem;
    margin-left: 2vw;
    p{
        color: var(--dark-color);
        padding-right: 12px;
        transition: 0.75s;
    }
    img{
        filter: invert(90%);
        scale: 1.2;
        transition: 0.75s;
    }
    transition: 0.75s;
}


.contact-button:hover{
    cursor: pointer;
    background-color: var(--dark-color);
    p{
        color: var(--light-color);
        transition: 0.75s;
        cursor: pointer;
    }
    img{
        filter: invert(0%);
        rotate: 45deg;
        transition: 0.75s;
        cursor: pointer;
    }

    
    transition: 0.75s;
    
    

}

.nav-divider{
    display: flex;
    align-items: center;
}


.hamburger{
    display: none;
}

.nav-item{
    padding: 1rem;
}

.nav-close{
    display: none;
}

.nav-divider-mobile{
    display: none;
}

@media screen and (max-width: 550px){
    .nav-divider{
        display: none;
    }
    .nav-container{
        padding: 2rem 2rem;
    }
    


    .nav-divider-mobile{
        display: none;
        padding: calc(var(--vertical-padding) + 3rem) var(--horizontal-padding);
        background-color: var(--dark-color);
        position: fixed; 
        height: 100%;
        top: 0;
        left: 0; 
        right: 0; 
        flex-direction: column;
        align-items: center;
        z-index: 10000;
        gap: 40px;

        
    }

    .hamburger{
        display: block;
    }

    
   

    .x-button-holder{
        display: flex;
        width: 100%;
    }

    .nav-close{
        display: block;
        color: var(--light-color)
    }
    .nav-items{
        flex-direction: column;
        justify-content: center;
        
    }
    .nav-item{
        color: var(--light-color);
        text-align: center;
        font-size: 30px;
    }

    .contact-button{
        
        width: 160px;
        margin-left: 0px;
        border-width: 2px;
        border-color: var(--light-color);
        p{
            color: var(--light-color);
            font-size: 20px;
        }
        img{
            filter: invert(0%);
            height: 10px;
        }
    }

    h1{
        font-size: 20px;

    }
    
    h2{
        font-size: 20px;

    }

    h3{
        font-size: 20px;

    }

    h4{
        font-size: 48px;

    }

    h5{
        font-size: 24px;

    }

    
}

@media screen and (max-width: 770px){

    .nav-divider{
        display: none;
    }
    .nav-container{
        padding: 2rem 2rem;
    }
    
    .nav-divider-mobile{
        display: none;
        padding: calc(var(--vertical-padding) + 3rem) var(--horizontal-padding);
        background-color: var(--dark-color);
        position: fixed; 
        height: 100%;
        top: 0;
        left: 0; 
        right: 0; 
        flex-direction: column;
        align-items: center;
        z-index: 10000;
        gap: 40px;

        
    }

    .hamburger{
        display: block;
    }

    
   

    .x-button-holder{
        display: flex;
        width: 100%;
    }

    .nav-close{
        display: block;
        color: var(--light-color)
    }
    .nav-items{
        flex-direction: column;
        justify-content: center;
        
    }
    .nav-item{
        color: var(--light-color);
        text-align: center;
        font-size: 30px;
    }

    .contact-button{
        
        width: 160px;
        margin-left: 0px;
        border-width: 2px;
        border-color: var(--light-color);
        p{
            color: var(--light-color);
            font-size: 20px;
        }
        img{
            filter: invert(0%);
            height: 10px;
        }
    }


}