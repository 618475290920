.testimonials-container{
    padding: var(--vertical-padding) calc(var(--horizontal-padding) + 2.5rem);
    height: 100vh;

    li{
        flex: 1 1 0;
    }
}

.testimonial-name{
    font-size: 1rem;
    font-weight: 700;
    padding-bottom: 0.2rem;
}


.testimonial-bottom{
    display: flex;
    align-items: center;
}

.profile-picture{
    height: 4.75rem;
    border-radius: 50%;
    margin-right: 0.75rem;
}

.testimonial-title{
    font-size: 0.85rem;
    font-weight: 400;
    color: gray;
}

.testimonials-list{
    overflow-x: scroll;
    display: flex;
    padding-top: 3rem;
}

.testimonial-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4rem 3rem;
    border-radius: 25px;
    margin-right: 2rem;
    background-color: var(--light-color);
    border: solid 1.5px var(--dark-color);
    height: 375px;

    .testimonial-content{
        color: var(--dark-color);
        font-size: 1rem;
        
    }
}


@media screen and (max-width: 1150px){

.testimonial-content{
    font-size: 0.9rem !important;
}
}

@media screen and (max-width: 1020px){
    .testimonials-container{
        height: auto;
        padding: 2rem 2rem;
        
        
    }

    .testimonials-list{
        display: block;
        overflow: hidden;
    }

    .testimonial-container{
        margin: 1rem 0;
        height: 300px;
        padding: 2rem 2rem;
        
        
    }

   
}

@media screen and (max-width: 600px){

    .testimonials-container{
        

            .testimonial-content{
                font-size: 0.7rem;
            }

            .testimonial-name{
                font-size: 0.75rem;
            }

            .testimonial-title{
                font-size: 0.6rem;
            }

            .profile-picture{
                height: 3rem;
            }

            .quote-icon{
                scale: 0.75;
            }
        
        
    }

}