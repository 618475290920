.hero-container{
   
    height: 70vw;
    padding: 0 var(--horizontal-padding);
}

.hero-text{
    padding: 1rem 0px 1.2rem 0px;
    line-height: 110px;
}

.hero-mobile-image{
    display: none;
}

.hero-middle{
    display: inline-block;
    position: relative;
    height: 100%;
    width: 100%;
}
 
    

.mask{
    position: absolute;
    mask-repeat: no-repeat;
    mask-size: contain;
}


.hero-img{
    transform-origin: bottom center 0px;
    transform: translateY(-200px);
    width: 100%;
}

.scroll-button{
    position: absolute;
    top: 30%;
    right: -1%;
    width: 150px;
    height: 50px;
    rotate: 90deg;
}
.hero-description{
    position: absolute;
    font-size: 1.25vw;
    top: 1.2%;
    right: 0;
    width: 24vw;
}

.scroll-button:hover{
    cursor: pointer;
    .arrow{
        overflow-x: visible;
        width: 110px;
        transition: 1s;
        
    }
}




.arrow {
    overflow-x: visible;
    box-sizing: border-box;
    position: relative;
    color: var(--dark-color);
    display: block;
    transform: scale(var(--ggs,1));
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    box-shadow: inset 0 0 0 2px;
    width: 70px;
    height: 5.5px;
    transition: 1s;
}



.arrow::after {
    overflow-x: visible;
    content: "";
    color: var(--dark-color);
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 7px;
    height: 7px;
    border-top: 1.5px solid;
    border-right: 1.5px solid;
    transform: rotate(45deg);
    right: 0;
    bottom: -2.85px
}

@media screen and (max-width: 550px){

    .hero-middle{
        display: inline-block;
        position: relative;
        height: auto;
        width: 100%;
    }
    .hero-container{
        height: auto;
        padding: 5vw 2rem !important;
    }
    .scroll-button{
        display: none;
    }

    .hero-text{
        font-size: 11vw !important;
        line-height: 10vw !important;
        font-weight: 800 !important;
    }

    .hero-description{
        font-size: 4vw !important;
        width: 100%;
        position: initial;
    }

    .mask{
        display: none;
    }

    .hero-mobile-image{
        display: block;
        width: 100%;
        border-radius: 20px;
        margin-top: 30px;
    }

}



@media screen and (max-width: 950px){
    .hero-middle{
        display: inline-block;
        position: relative;
        height: auto;
        width: 100%;
    }
    .hero-container{
        height: auto;
        padding: 5vh 2rem;
    }
    .scroll-button{
        display: none;
    }

    .hero-text{
        font-size: 12vw;
        line-height: 12vw;
        font-weight: 700;
    }

    .hero-description{
        font-size: 3vw;
        width: 100%;
        position: initial;
    }

    .mask{
        
        display: none;
    }

    .hero-mobile-image{
        display: block;
        width: 100%;
        height: 375px;
        object-fit: cover;
        border-radius: 20px;
        margin-top: 30px;
    }

  

}

