.quote{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    
}

.success-message{
    display: none;
    transition: 0.5s ease-out;
}

.failure-message{
    display: none;
}

.overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: rgba(36,41,46,0.75);
    
    
}

.quote-content{

    background-color: rgba(255, 255, 255, 0.5);
    width: 80%;
    height: 75%;
    border-radius: 50px;
    backdrop-filter: blur( 15px );
    -webkit-backdrop-filter: blur( 15px );
    border: solid 0.5px var(--light-color);
    .quote-body{
        gap: 30px;
        height: auto;
        display: flex;
        justify-content: space-between;
        padding: 0 3.2rem;
    }
    h5{
        padding-top: 4vw;
        width: 500px;
        font-weight: 400;
        span{
            font-weight: 700;
        }
    }

    img{
        cursor: pointer;
        padding: 3rem 3rem 2rem;
    }
}

.quote-form{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.send-button{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 45px;
    border-radius: 50px;
    background-color: var(--dark-color);
    transition: 0.5s ease-in-out;
    color: var(--light-color);
}

.send-button:hover{
    opacity: 0.75;
    transition: 0.5s ease-in-out;
}

input {
    user-select: none; // chrome and Opera
    -moz-user-select: none; // Firefox
    -webkit-text-select: none; // IOS Safari
    -webkit-user-select: none;
    width: 500px;
    height: 28px;
    border: none;
    border-bottom: 1.5px solid var(--dark-color);
    background-color: transparent;
    margin: 0.75rem 0;
    color: var(--dark-color);
    font-size: 1rem;
    
}

textarea:focus, input:focus{
    outline: none;
}

textarea::placeholder, input::placeholder{
    color: var(--dark-color);
    font-size: 1rem;
  }

textarea{
    border: 1.5px solid var(--dark-color);
    padding: 1rem;
    margin: 0.75rem 0;
    border-radius: 10px;
    width: 470px;
    height: 20vh;
    background-color: transparent;
    resize: none;
    user-select: none;
    color: var(--dark-color);
    font-size: 1rem;
    
}

@media screen and (max-width: 1050px){
    .quote-content{
        width: 80%;
        height: 80%;
        .quote-body{
            display: block;
            h5{
                display: none;
            }
            span{
                display: none;
            }
        }}

}


@media screen and (max-width: 770px){
    .quote{
        z-index: 10000;
        
    }
    .quote-content{
        border-radius: 0;
        
        width: 100%;
        height: 100%;
        .quote-body{
            display: block;
            h5{
                display: none;
            }
            span{
                display: none;
            }
        }

        input{
            width: calc(100% - 4px);
        }

        textarea{
            height: 20vh;
            width: calc(100% - 2rem - 3px);
        }

        .send-button{
            width: 100%;
        }
    }
}

@media screen and (max-width: 450px){
    textarea{
        height: 20vh;
        width: calc(100% - 2rem - 3px);
    }

    input{
        width: calc(100% - 4px);
    }
}

@media screen and (max-height: 640px) {

    textarea{
        height: 8vh;
    }

    
    
}