.services-container{
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 2.5rem var(--horizontal-padding) 0px var(--horizontal-padding);
    h4{
        color: var(--light-color);
        text-align: center;
        font-weight: bold;
    }
    h6{
        color: var(--light-color);
        text-align: center;
        font-weight: 400;
    }
    

}

.services-grid {
    
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 0.3fr 0.3fr 1fr;
    grid-column-gap: 1.3rem;
    grid-row-gap: 1.3rem;
    z-index: 1000;
    justify-content: space-evenly;
    align-content: center;
    justify-content: center;
    align-content: space-evenly;
    padding: 4rem 10rem;
}



.service{
    position: relative;
    align-items: flex-end;
    display: flex;
    height: 35vw;
    border-radius: 25px;
    box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.30);
    

    div{
        position: relative;
        margin-bottom: 2rem;
        margin-left: 2rem;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.75rem 2rem;
        border: solid 0.5px #7a7a7a;
        background-color: rgba($color: #383636, $alpha: 0.4);
        backdrop-filter: blur(4px) saturate(180%);
        -webkit-backdrop-filter: blur(4px) saturate(180%);
        border-radius: 50px;
        z-index: 10000;
    }
}

.service-image{
    position: absolute;
    border-radius: 20px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow-clip-margin: content-box;
    overflow: clip;
    
}


.webdev-service{
    grid-area: 1/1/3/4;
}

.mobiledev-service{
    grid-area: 1/4/3/5;
}

.ai-service{
    grid-area: 3/1/5/2;
}

.uiux-service{
    grid-area: 3/2/5/5;
}

.mobiledev-service{
    background-position: -100px;
}

@media screen and (max-width: 550px){
    .services-container{
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 2rem 2rem;
        h4{
            color: var(--light-color);
            text-align: left;
            font-weight: bold;
            font-size: 48px;
        }
        h6{
            color: var(--light-color);
            text-align: left;
            font-weight: 400;
            font-size: 18px;
        }
    }

    .services-grid{
        display: block !important;
        padding: 2rem 0 !important;
    }

    .service{
        border-radius: 25px !important;
        height: 250px !important;
        width: 100% !important;
        margin: 1rem 0 !important;
        div{
            height: 20px;
            margin-bottom: 1rem;
            margin-left: 1rem;
            h6{
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 1024px){

    .services-container{
        height: auto;
    }

    .services-grid {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 1.3rem;
        grid-row-gap: 1.3rem;
        z-index: 1000;
        justify-content: space-evenly;
        align-content: center;
        justify-content: center;
        align-content: space-evenly;
        padding: 2rem 1rem;
    }

    .service{
        height: 400px;
        
        border-radius: 25px;
        div{
            padding: 0.5rem 1.25rem;
            margin: 1rem 1rem;
            h6{
                font-size: 18px;
                text-align: left;
            }
        }
    }

    .mobiledev-service{
        grid-area: 1/3/3/5;
    }

    .webdev-service{
        grid-area: 1/1/3/3;
    }

    .uiux-service{
        grid-area: 3/1/5/3;
    }

    .ai-service{
        grid-area: 3/3/5/5;
    }

}
